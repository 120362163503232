<template>
  <button
    class="group bg-gray-200 flex-col flex p-5 rounded-xl hover:bg-opacity-70 gap-2 flex-grow"
    @click="$emit('open')"
  >
    <div class="text-xl font-semibold">{{ map.year }}</div>
    <div class="text-sm text-gray-500">
      <v-clamp autoresize :max-lines="1">
        Total de {{ userMap.days_count.total }} dias
        <span v-if="userMap.days_count.csv_credit"> + {{ userMap.days_count.csv_credit }} dias de crédito anual</span>

        <div v-if="userMap.days_count.remaining > 0 || userMap.days_count.credit_remaining > 0">
          {{ userMap.days_count.remaining }}
          <span v-if="userMap.days_count.csv_credit"> + {{ userMap.days_count.credit_remaining }} </span>
          disponíveis
        </div>

        <div>
          {{ userMap.days_count.selected }}
          <span v-if="userMap.days_count.csv_credit"> + {{ userMap.days_count.credit_selected }}</span>
          marcados
        </div>
      </v-clamp>
    </div>
    <div>
      <fw-tag :type="index === 0 ? colors[userMap.status] : 'xlight'" size="xs">
        {{ statusLabels[userMap.status]?.['pt'] }}
      </fw-tag>
    </div>
  </button>
</template>

<script>
import { MAP_USER_STATUS_COLORS, MAP_USER_STATUS } from '@/utils/index.js'

export default {
  props: {
    user: {
      type: Object,
    },
    userMap: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
      required: false,
    },
  },

  data() {
    return {
      colors: MAP_USER_STATUS_COLORS,
      statusLabels: MAP_USER_STATUS,
    }
  },

  computed: {
    language() {
      return this.$store.state.language
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "status": {
      "open": "Aberto",
      "closed": "Submetido",
      "submitted": "Submetido",
      "sealed": "Fechado"
    }
  },
  "en": {
    "status": {
      "open": "Aberto",
      "closed": "Submetido",
      "submitted": "Submetido",
      "sealed": "Fechado"
    }
  }
}
</i18n>
